
import { httpGet, httpPost } from '@/api';
import { computed, reactive, ref, toRefs } from 'vue';
import { erpConfirm, errorMessage, getRouterParams, successMessage } from '@/utils/publicMethods';
import router from '@/router';
import BaseInfo from './components/BaseInfo/index.vue';
import CuleForm from './components/ClueForm/ClueForm.vue';
import ViewContact from './components/ViewContact/index.vue';
import ViewFrameWork from './components/ViewFrameWork/index.vue';
import ViewBussiness from './components/ViewBussiness/index.vue';
import ViewDemand from './components/ViewDemand/index.vue';
import ChooseMan from '@/components/ChooseMan/ChooseMan.vue';
import useVisible from '@/hooks/useVisible';
import ViewTask from '@/views/crm/clue/components/ViewTask/index.vue';
import ViewCommunicate from '@/views/crm/clue/components/ViewCommunicate/index.vue';
import ViewAttachment from '@/views/crm/clue/components/ViewAttachment/index.vue';

export default {
  name: 'ContactDetails',
  components: {
    BaseInfo,
    ViewCommunicate,
    ViewContact,
    ViewTask,
    ViewAttachment,
    ViewDemand,
    CuleForm,
    ChooseMan,
    ViewFrameWork,
    ViewBussiness,
  },
  props: {},
  setup() {
    // 获取路由参数
    const { id, type: paramType, backFrom } = getRouterParams('ContactDetails');
    // 当前列表状态
    const type = ref(paramType as number);
    const detailsData = ref();
    const load = ref(true);
    const chooseRef = ref();
    /** 编辑客户展示 */
    const { visible, showVisible } = useVisible();

    const leftTitleNames = reactive({
      ViewContactTitle: '联系人',
      ViewCommunicateTitle: '沟通记录',
      ViewTaskTitle: '任务',
      ViewDemandTitle: '需求',
      ViewBussinessTitle: '商机',
      ViewFrameWorkTitle: '框架合同',
      ViewEnclosureTitle: '附件',
    });
    // 客户框架合同大于1才显示
    const frameWorkShow = ref(false);
    // 是否能编辑
    const isHidden = computed(() => {
      return type.value > 1;
    });
    const scoreName = computed(() => {
      return detailsData.value?.customerName;
    });
    const back = () => {
      if (backFrom) {
        router.push(backFrom);
        return;
      }
      // TODO 改造成 from判断
      if (paramType === 1) {
        router.push('/client/clientList');
      }
      if (paramType === 2) {
        router.push('/client/clientpoop');
      }
      if (paramType === 3) {
        router.push('/client/recycleBin');
      }
    };
    // 初始化数据
    const initData = async () => {
      load.value = false;
      const res = await httpGet(`/malicrm/customer/customerDetail/${id}`);
      if (res.code === 200) {
        detailsData.value = res.result;
        load.value = true;
      } else {
        // 这里传个12什么意思
        if (res.code === 12) {
          // todo 这里也不知道这里作什么的，到时候调查下
        } else {
          errorMessage(res.message);
          if (res.code === 9999100) {
            back();
          }
        }
      }
    };
    const closeVisible = (status) => {
      visible.value = false;
      if (status) {
        initData();
      }
    };
    // 设置标题方法
    const getViewCommunicateTitle = (str) => {
      leftTitleNames.ViewCommunicateTitle = str;
    };
    const getViewTaskTitle = (str) => {
      leftTitleNames.ViewTaskTitle = str;
    };
    const getViewEnclosureTitle = (str) => {
      leftTitleNames.ViewEnclosureTitle = str;
    };
    const getViewContactTitle = (str) => {
      leftTitleNames.ViewContactTitle = str;
    };

    const getViewDemandTitle = (str) => {
      leftTitleNames.ViewDemandTitle = str;
    };
    const getViewBussinessTitle = (str) => {
      leftTitleNames.ViewBussinessTitle = str;
    };
    const getViewFrameWorkTitle = (str: string, total: number) => {
      leftTitleNames.ViewFrameWorkTitle = str;
      frameWorkShow.value = total > 0;
    };

    // 分配客户
    const distributionLeads = () => {
      chooseRef.value.showVisible();
    };
    // 分配客户触发
    const setDistributionLeads = async (ids) => {
      const params = {
        id: detailsData.value.id,
        version: detailsData.value.version,
        principalIds: ids,
      };
      const res = await httpPost('/malicrm/customer/assignCustomer', params);
      if (res.code === 200) {
        successMessage('分配成功！');
        router.push('/client/clientpoop');
      } else {
        errorMessage(res.message);
      }
    };

    // 删除
    const deleteLeads = () => {
      erpConfirm('确定要删除当前客户吗？').then(async () => {
        const params = {
          id: detailsData.value.id,
          version: detailsData.value.version,
          operateType: 1,
        };
        const res = await httpPost('/malicrm/customer/deReCustomer', params);
        if (res.code === 200) {
          successMessage('已移入回收站');
          if (type.value === 1) {
            // 客户列表
            router.push('/client/clientList');
          } else {
            // 客户池
            router.push('/client/clientpoop');
          }
        } else {
          errorMessage(res.message);
        }
      });
    };
    // 还原
    const reductionLeads = () => {
      erpConfirm('确定要还原当前客户吗？').then(async () => {
        const params = {
          id: detailsData.value.id,
          version: detailsData.value.version,
          operateType: 2,
        };
        const res = await httpPost('/malicrm/customer/deReCustomer', params);
        if (res.code === 200) {
          successMessage('已还原');
          type.value = 1;
          initData();
        } else {
          errorMessage(res.message);
        }
      });
    };
    // 返回操作

    initData();
    return {
      load,
      id,
      type,
      isHidden,
      detailsData,
      back,
      ...toRefs(leftTitleNames),
      getViewCommunicateTitle,
      getViewTaskTitle,
      getViewEnclosureTitle,
      getViewContactTitle,
      getViewDemandTitle,
      getViewBussinessTitle,
      getViewFrameWorkTitle,
      frameWorkShow,
      deleteLeads,
      distributionLeads,
      chooseRef,
      setDistributionLeads,
      reductionLeads,
      scoreName,
      visible,
      showVisible,
      closeVisible,
    };
  },
};

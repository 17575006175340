
import { defineComponent, ref } from 'vue';
import Pagination from '@/components/Pagination/Pagination.vue';
import usePagination from '@/hooks/usePagination';
import useVisible from '@/hooks/useVisible';
import ViewForm from '@/views/crm/documents/cusFramework/components/ViewContractForm.vue';
import { setTitleNum, hasPermission } from '@/utils/publicMethods';
export default defineComponent({
  name: 'ViewFrameWork',
  /** data 客户或 商机信息 */
  props: ['eventId', 'eventType', 'isHidden', 'data'],
  components: { Pagination, ViewForm },
  emits: ['getSize'],
  setup(props, ctx) {
    const queryData = {
      id: props.eventId,
      pageNum: 1,
      pageSize: 5,
    };
    const { paginationRef, tableData, refashTable } = usePagination();
    const formType = ref(1); // 1新增2编辑 3删除
    const rowData = ref();
    const { visible } = useVisible();
    // 获取表格数据操作
    const getTableDatas = (data: Array<any>, total: number) => {
      if (data) {
        data.forEach((item) => {
          for (const key in item) {
            if (item[key] === '' || item[key] === null) {
              item[key] = '-';
            }
          }
        });
        tableData.value = data;
      }
      tableData.value = data;
      ctx.emit('getSize', setTitleNum('框架合同', total), total);
    };
    // 新增编辑操作
    // 新增编辑操作
    const operateTask = (type, row?) => {
      formType.value = type;
      if (row) {
        rowData.value = row;
      }
      visible.value = true;
    };
    // 表单关闭操作，重新触发数据更新
    const closeVisible = () => {
      visible.value = false;
      refashTable();
    };

    return {
      tableData,
      queryData,
      getTableDatas,
      closeVisible,
      visible,
      formType,
      paginationRef,
      hasPermission,
      rowData,
      operateTask,
    };
  },
});


// 线索基础模块信息
import { defineComponent } from 'vue';
import useFilter from '@/hooks/useFilter';
import { showFiles } from '@/utils/publicMethods';
import TableFileContent from '@/components/TableFileContent/TableFileContent.vue';
export default defineComponent({
  name: 'clueClientInfo',
  props: ['data'],
  components: {
    TableFileContent,
  },
  setup() {
    const { empty } = useFilter();
    const isFile = (data) => {
      return data !== null && data !== '';
    };
    return { empty, showFiles, isFile };
  },
});

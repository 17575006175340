import { reactive, ref } from 'vue';

export default (params) => {
  const formRef = ref(); // 表单校验对象使用
  type CommuniData = {
    email: string; // 邮箱
    position: string; // 职位
    name: string; // 姓名
    sex: 1 | 2;
    remark?: string;
    telephone: string;
    weixin: string;
    businessId?: string | number;
    customerId: string | number;
    id: string | null;
    customerName?: string;
    [propName: string]: any;
  };
  const formData: CommuniData = reactive({
    email: '', // 邮箱
    position: '', // 职位
    name: '', // 姓名
    sex: 1,
    remark: '',
    telephone: '',
    weixin: '',
    businessId: '',
    customerId: '',
    id: null,
    customerName: '',
    ...params,
  });

  const rules = reactive({
    name: [
      {
        required: true,
        message: '请输入联系人',
        trigger: 'blur',
      },
      {
        max: 20,
        message: '最大长度为20',
        trigger: 'blur',
      },
    ],
    customerName: [
      {
        required: true,
        message: '',
        trigger: 'blur',
      },
    ],
    telephone: [
      {
        required: true,
        message: '请输入联系电话',
        trigger: 'blur',
      },
    ],
  });

  return { rules, formData, formRef };
};


import { httpPost } from '@/api';
import {
  ajaxLoading,
  errorMessage,
  successMessage,
  setTitleLabel,
} from '@/utils/publicMethods';

import { defineComponent, PropType, ref } from 'vue';
import useFormData from './useData';
export default defineComponent({
  components: {},
  props: {
    /** 表单新增编辑状态 1新增2编辑 3删除 */
    formType: {
      type: Number as PropType<number>,
    },
    /** 客户id  */
    eventId: {
      type: Number as PropType<number>,
    },
    /** 事件状态 1.客户 2.商机 */
    eventType: {
      type: Number as PropType<number>,
    },
    title: {
      type: String as PropType<string>,
    },
    data: {
      type: Object as PropType<any>,
    },
    /** 客户或商机信息 */
    eventData: {
      type: Object as PropType<any>,
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    let paramData = {
      operateType: props.formType,
      customerId: props.eventId,
      source: props.eventType,
      customerName: (props.eventData as any).customerName,
    };
    const spaceHoder = ref('-');
    if ((props.formType as number) > 1) {
      paramData = Object.assign(paramData, props.data);
    }
    const { rules, formData, formRef } = useFormData(paramData);
    const close = (status = false) => {
      ctx.emit('close', status);
    };
    const submitForm = async () => {
      ajaxLoading.lock();
      const res = await httpPost('/malicrm/customer/operateContacts', formData);
      ajaxLoading.unLock();
      if (res.code === 200) {
        if (props.formType === 1) {
          successMessage('添加成功', 2000);
        } else {
          successMessage('编辑成功', 2000);
        }
        close(true);
      } else {
        errorMessage(res.message);
      }
    };
    /** 提交表单 校验重复 */
    const saveFormData = async () => {
      formRef.value.validate(async (valid: any) => {
        if (valid) {
          const params = {
            contactId: formData.id, // 联系人id ,
            customerId: formData.eventId, // 客户id ,
            sex: formData.sex, // 性别 ,
            name: formData.name,
          };
          const res = await httpPost(
            '/malicrm/customer/checkContactActive',
            params
          );
          if (res.code === 200) {
            submitForm();
          } else {
            errorMessage(res.message);
          }
        }
      });
    };
    const changeInput = (data: any, name: string, value: string) => {
      value = value.replace(/[^-\d.]/g, ''); // 清除“数字”和“.”以外的字符
      value = value.replace(/\.{2,}/g, '.'); // 只保留第一个. 清除多余的
      value = value.replace(/-{2,}/g, ''); // 只保留第一个. 清除多余的
      value = value.replace('-', '$#$').replace(/-/g, '').replace('$#$', '-');
      value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
      // 只能输入两个小数
      value = value.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      data[name] = value;
    };
    return {
      close,
      formData,
      saveFormData,
      rules,
      formRef,
      setTitleLabel,
      changeInput,
      spaceHoder,
    };
  },
});


import { httpPost } from '@/api';
import {
  ajaxLoading,
  errorMessage,
  successMessage,
  setTitleLabel,
} from '@/utils/publicMethods';
import { defineComponent, PropType } from 'vue';
import useFormData from './useData';
import useCountryPlant from '@/hooks/useCountryPlant';
type PropData = {
  category: number | string;
  country: string;
  supplierCompanyType: string;
};
export default defineComponent({
  components: {},
  props: {
    /** 表单新增编辑状态 1新增2编辑 3删除 */
    formType: {
      type: Number as PropType<number>,
    },
    /** 客户id  */
    eventId: {
      type: String as PropType<string>,
    },
    /** 事件状态 1.客户 2.商机 */
    eventType: {
      type: Number as PropType<number>,
    },
    data: {
      type: Object as PropType<PropData>,
    },
    /** 客户或商机信息 */
    eventData: {
      type: Object as PropType<any>,
    },
  },
  emits: ['close'],
  setup(props, ctx) {
    const { setCountryList, countryList } = useCountryPlant();
    const { rules, formData, formRef, setFormData } = useFormData();
    const close = (status = false) => {
      ctx.emit('close', status);
    };

    /** 获取国家信息 */
    const queryCountry = (value: any) => {
      setCountryList(value);
      formData.country = [];
    };
    const submitForm = async (params: any) => {
      ajaxLoading.lock();
      const res = await httpPost(
        '/malicrm/customer/operateCustomerDemand',
        params
      );
      ajaxLoading.unLock();
      if (res.code === 200) {
        successMessage('已保存', 2000);
        close(true);
      } else {
        errorMessage(res.message);
      }
    };
    /** 提交表单 校验重复 */
    const saveFormData = async () => {
      formRef.value.validate(async (valid: any) => {
        if (valid) {
          // 校验通过后处理数据
          const params = JSON.parse(JSON.stringify(formData));
          if (params.supplierCompanyType.length > 0) {
            params.supplierCompanyType = params.supplierCompanyType.join(',');
          } else {
            params.supplierCompanyType = null;
          }
          if (params.country.length > 0) {
            params.country = params.country.join(',');
          } else {
            params.country = null;
          }
          if (params.productNameKeyword && params.productNameKeyword !== '') {
            params.productNameKeyword = params.productNameKeyword.replace(
              '，',
              ','
            );
          }
          submitForm(params);
        }
      });
    };
    // 编辑数据
    const editForm = () => {
      // 这里全部都是业务数据转化处理
      let paramData = {
        eventId: props.eventId,
        customerId: props.eventId,
        source: props.eventType,
        country: [],
        supplierCompanyType: [],
      };
      paramData = Object.assign(paramData, props.data);
      if (props.data?.country) {
        (paramData.country as number[]) = props.data?.country
          .split(',')
          .map((v) => Number(v));
      }
      if (
        props.data?.supplierCompanyType &&
        props.data?.supplierCompanyType !== ''
      ) {
        (paramData.supplierCompanyType as number[]) = props.data?.supplierCompanyType
          .split(',')
          .map((v) => Number(v));
      }
      // 这里全部都是业务数据转化处理
      setCountryList(props.data?.category); // 设置国家列表信息
      setFormData(paramData);
    };
    if ((props.formType as number) > 1) {
      editForm();
    } else {
      formData.customerId = props.eventId as string;
    }
    return {
      close,
      formData,
      saveFormData,
      rules,
      formRef,
      setTitleLabel,
      countryList,
      queryCountry,
    };
  },
});

import { reactive, ref } from 'vue';
export default () => {
  const formRef = ref(); // 表单校验对象使用
  type CommuniData = {
    category: number | null;
    country: number[] | [];
    customerId: string | null;
    id: string | null;
    productNameKeyword: string | null;
    remark: string | null;
    supplierCompanyType: number[] | string[];
    version: number;
    [propName: string]: any;
  };
  const formData: CommuniData = reactive({
    category: null,
    country: [],
    customerId: null,
    id: null,
    productNameKeyword: null,
    remark: '',
    supplierCompanyType: [],
    version: 0,
  });
  const setFormData = (paramsData: any) => {
    for (const key in paramsData) {
      if (Object.prototype.hasOwnProperty.call(paramsData, key)) {
        formData[key] = paramsData[key];
      }
    }
  };
  const rules = reactive({
    category: [
      {
        required: true,
        message: '请选择大类',
        trigger: 'change',
      },
    ],
  });

  return { rules, formData, formRef, setFormData };
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b9d3f26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mali-edit__form" }
const _hoisted_2 = { class: "mali-edit__form" }
const _hoisted_3 = { class: "crm-button__group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DictSelect = _resolveComponent("DictSelect")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_CrmFormLayout = _resolveComponent("CrmFormLayout")!

  return (_openBlock(), _createBlock(_component_CrmFormLayout, {
    title: _ctx.setTitleLabel(_ctx.formType, ['添加需求', '编辑需求']),
    onClose: _ctx.close
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        model: _ctx.formData,
        "label-position": "top",
        ref: "formRef",
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "大类",
                      prop: "category"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          modelValue: _ctx.formData.category,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.category) = $event)),
                          name: "goods_category_label",
                          placeholder: "请选择大类",
                          onChange: _ctx.queryCountry
                        }, null, 8, ["modelValue", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "国家",
                      prop: "country"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          modelValue: _ctx.formData.country,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.country) = $event)),
                          placeholder: "请选择国家",
                          customOption: _ctx.countryList,
                          "key-label": "countryName",
                          "key-value": "country",
                          multiple: "",
                          "multiple-limit": 5
                        }, null, 8, ["modelValue", "customOption"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "供应商类型",
                      prop: "supplierCompanyType"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DictSelect, {
                          modelValue: _ctx.formData.supplierCompanyType,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.supplierCompanyType) = $event)),
                          name: "supplier_company_type",
                          placeholder: "请选择供应商类型",
                          multiple: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 12 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "品名关键词",
                      prop: "productNameKeyword"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.productNameKeyword,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.productNameKeyword) = $event)),
                          clearable: "",
                          disabled: _ctx.formType > 2,
                          maxlength: 30,
                          placeholder: "多个品名请以 , 隔开 , 例如：全牛 , 牛腩"
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_el_row, { gutter: 80 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 24 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "备注",
                      prop: "remark"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.formData.remark,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.remark) = $event)),
                          placeholder: "",
                          maxlength: 100,
                          class: "mali-full__input",
                          type: "textarea",
                          resize: "none",
                          rows: 5,
                          disabled: _ctx.formType > 2
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.formType < 3)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "crm-form__button primary",
              onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.saveFormData && _ctx.saveFormData(...args)))
            }, " 保存 "))
          : _createCommentVNode("", true),
        (_ctx.formType < 3)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              class: "crm-form__button cancel",
              onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
            }, " 取消 "))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose"]))
}